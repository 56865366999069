import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Order.module.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import "../Account/invoice.css";
import { showSuccessToast, showErrorToast } from "../../../Toster";

const validationRules = {
  // designation_name: Yup.string()
  // 	.max(120, 'Name must not exceed 120 characters')
  // 	.required('Name is required'),
  // salesman_id: Yup.string().required('Sales Person is required'),
  // dealer_id: Yup.string().required('Dealer is required'),
  // areaManager_id: Yup.string().required('Area Manager is required'),
  // stock_id: Yup.string().required('Product  is required'),
  // qty: Yup.string().required('Quantity is required'),
};

const EditOrder = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const currentLogedInUserId = JSON.parse(localStorage.getItem("userDetails"));
  let { id } = useParams();

  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [salesRoles, setSalesRoles] = useState([]);
  const [dealerRoles, setDealerRoles] = useState([]);
  const [areaManagerRoles, setAreaManagerRoles] = useState([]);
  const [seasonRoles, setSeasonRoles] = useState([]);
  const [seasonDropDown, setSeasonDropDown] = useState(false);
  const [dearlerDropDown, setDealerDropDown] = useState(false);
  const [salesmanDropDown, setSalesmanDropDown] = useState(false);
  const [areamanagerDropDown, setAreaManagerDropDown] = useState(false);
  const [selectDiscountType, setSelectDiscountType] = useState();
  const [logicticDropDown, setLogisticDropDown] = useState(false);
  const [logicticRoles, setLogisticRoles] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [getData, setGetData] = useState({});
  const [freezed, setFreezed] = useState(false);
  const [user_id, setUserId] = useState();
  const [userData, setUserData] = useState();
  const [tableData, setTableData] = useState([
    {
      stock_id: "",
      price: "",
      qty: "",
      shipping_address_id: "",
    },
  ]);
  const [data, setData] = useState([]);
  const [orderNo , setOrderNo] = useState("");

  // get by id data
  useEffect(() => {
    let local_id = JSON.parse(localStorage.getItem("userDetails"));
    setUserId(local_id);
    var loginUser = local_id;
    setIsLoading(true);
    //Getting Order Info by id start
    axiosPrivate
      .get(`orders/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          setFreezed(
            response.data.data.order_status == "confirmed" ? true : false
          );
          setGetData({ ...response.data.data });
          formik.setValues({ ...response.data.data });
        }
        if (getData.discount_type === "Season Discount") {
          setSeasonDropDown(true);
        } else if (getData.discount_type != "Season Discount") {
          setSeasonDropDown(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {});
    //Getting Order Info by id end
  }, [id]);
  // get by id end

  //Getting Dealer Shipping addresses start
  useEffect(() => {
    if (getData.dealer_id != undefined && getData.dealer_id != null) {
      axiosPrivate
        .get(`/users/get/addresses/${getData.dealer_id}`)
        .then((addresses) => {
          setShippingAddress(addresses?.data?.data);
        });
    }
    if (getData.order_items != undefined) {
      setTableData(getData.order_items);
    }
    // sales person list
    axiosPrivate
      .get(`users/get/sales/person/${getData.areamanager_id}`)
      .then((salesPerson) => {
        setSalesRoles(salesPerson?.data?.data);
      })
      .catch((error) => {});
    // sales person end
  }, [getData]);
  //Getting Dealer Shipping addresses end

  const SeasondropdownClick = (event) => {
    if (event.target.value == "Season Discount") {
      setSeasonDropDown(true);
    } else if (event.target.value != "Season Discount") {
      setSeasonDropDown(false);
    }
    formik.setFieldValue("discount_type", event.target.value);

    setSelectDiscountType(event.target.value);
  };

  //Component mount function start
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
    setUserData(userData);
    if (userData.role == "3" || userData.role == "1") {
      setDealerDropDown(true);
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      setLogisticDropDown(true);
    } else if (userData.role == "4") {
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      setAreaManagerDropDown(false);
    }
    // Logistic list start
    axiosPrivate
      .get("users/get/logistic/manager")

      .then((logistics) => {
        setLogisticRoles(logistics?.data?.data);
      });

    // Logistic list end
    // Season list start
    axiosPrivate
      .post("seasons")
      .then((seasons) => {
        setSeasonRoles(seasons?.data?.data);
      })
      .catch((error) => {});
    // Season list end

    // dealer list start
    axiosPrivate
      .post("dealers")
      .then((dealers) => {
        setDealerRoles(dealers?.data?.data);
      })
      .catch((error) => {});
    // dealer list end
    // Area Manager list start
    axiosPrivate
      .get("users/get/area/manager")
      .then((AreaManager) => {
        setAreaManagerRoles(AreaManager?.data?.data);
      })
      .catch((error) => {});
    // Area Manager list end
    // product list start
    axiosPrivate
      .get("orders/get/products/list")
      .then((order) => {
        setRoles(order?.data?.data);
      })
      .catch((error) => {});

    // product list end
  }, []);
  //Component mount function end

  //Function to load sales person List
  const salesPersonListHandler = async (id) => {
    //Sales person list start
    if (id != undefined && id != "") {
      await axiosPrivate
        .get(`users/get/sales/person/${id}`)
        .then((salesPerson) => {
          setSalesRoles(salesPerson?.data?.data);
        });
    } else {
      setSalesRoles([]);
    }
    return true;
    //Sales person list end
  };

  //Load address id user is
  const setDealerIdHandler = (dealerId) => {
    if (dealerId != "") {
      //Get Dealer address
      axiosPrivate.get(`/users/get/addresses/${dealerId}`).then((addresses) => {
        setShippingAddress(addresses?.data?.data);
      });
      //Get Dealer address
    }
    formik.setFieldValue("dealer_id", dealerId);
    return true;
  };
  //Load address

  useEffect(() => {
    calculateTotalAmount();
  }, [tableData, seasonDropDown, discount]);

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    tableData.forEach((item) => {
      totalPrice += parseInt(item.price) * parseInt(item.qty);
    });
    return totalPrice;
  };

  const calculateTotalAmount = () => {
    let totalPrice = calculateTotalPrice();
    if (discount != null && discount > 0 && discount <= 100) {
      let discountAmount = parseFloat(discount);
      totalPrice -= (parseInt(totalPrice) / 100) * parseInt(discountAmount);
    }
    formik.setFieldValue("total_amount", totalPrice);
    return parseInt(totalPrice);
  };

  const addNewRow = (eve) => {
    eve.preventDefault();
    setTableData([
      ...tableData,
      {
        stock_id: "",
        price: "",
        qty: "",
        shipping_address_id: "",
      },
    ]);
  };

  const removeExpensesTask = (index, eve) => {
    eve.preventDefault();
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, i) => i !== index));
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      dealer_id: "",
      areamanager_id: "",
      salesman_id: "",
      stock_id: "",
      price: "",
      qty: "",
      discount_type: "",
      discount: "",
      total_amount: "",
      season_id: "",
      order_id: "",
      order_status: "",
      logistic_user_id: "",
      current_user_id: "",
      user_note: "",
      remark: "",
      image: "",
      area_manager_remark: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      // const data = {
      //   dealer_id: values.dealer_id || null,
      //   area_manager_id: values.areamanager_id || null,
      //   salesman_id: values.salesman_id || null,
      //   order_rows: tableData,
      //   discount_type: values.discount_type,
      //   discount: discount,
      //   totalAmount: calculateTotalAmount() || null,
      //   season_id: values.season_id || null,
      //   order_status: values.order_status || null,
      //   logistic_user_id: values.logistic_user_id || null,
      //   current_user_id: currentLogedInUserId.id || null,
      //   user_note: values.user_note || null,
      //   image: values.image || null,
      //   area_manager_remark:values.area_manager_remark,
      // };

      let formData = new FormData();
      formData.append("dealer_id", values.dealer_id);
      formData.append("area_manager_id", values.areamanager_id);
      formData.append("salesman_id", values.salesman_id);
      formData.append("order_rows", JSON.stringify(tableData));
      formData.append("discount_type", values.discount_type);
      formData.append("discount", values.discount);
      formData.append("totalAmount", calculateTotalAmount());
      formData.append("season_id", values.season_id);
      formData.append("order_status", values.order_status);
      formData.append("logistic_user_id", values.logistic_user_id);
      formData.append("current_user_id", currentLogedInUserId.id);
      formData.append("user_note", values.user_note);
      formData.append("image", values.image);
      formData.append("area_manager_remark", values.area_manager_remark);

      await axiosPrivate
        .post(`orders/update/${id}?_method=PATCH`, formData)
        .then((response) => {
          setAlertMessage({ message: "Order Updated Successfully" });
          setIsLoading(false);
          navigate("/admin/order");
        })
        .catch((error) => {
          // if (
          //   error.response.data?.errors &&
          //   typeof error.response.data?.errors == "array"
          // ) {
          //   showErrorToast(error.response.data.errors[0].msg);
          // } else {
          //   showErrorToast(error.response.data.message);
          // }
          setIsLoading(false);
        });

      if (values.order_status == "confirmed") {
        let object = {
          title: "Order Confirmation",
          order_id: tableData[0].order_id,
          user_id: values.logistic_user_id,
          notification_for: "logistic",
        };
        await axiosPrivate
          .post("/notifications/store", object)
          .then((res) => {})
          .error((err) => {});
      }
    },
  });

  const totalDispatchAmount = () => {
    let amount = 0;
    tableData.forEach((element) => {
      amount += element.dispatch_quantity * element.rate;
    });
    return amount;
  };
  let amount = totalDispatchAmount();

  const generateInvoice = (event, splitId, mergeId, orderId, orderNo) => {
    setIsLoading(true);
    event.preventDefault();
    axiosPrivate
      .post("accounting/generate/invoice", {
        order_id: orderId,
        split_id: splitId,
        merge_id: mergeId,
        godown_id: "2f7a93e9-1fc4-11ee-9c9b-b02628e88184",
      })
      .then(async (response) => {
        if (response.status == 201) {

          // setData(response.data.invoice);
          // await resetLogisticState();
          // await notificationFunction(orderId);
          await response?.data?.invoice?.forEach((item) => {
            let total_quantity = 0;

            // Extract quantities, convert to numbers, and sum them up
            total_quantity = item?.products?.reduce((acc, product) => {
              return acc + Number(product?.quantity);
            }, 0);

            item.quantity = total_quantity;
          });

          for(let i = 0; i < response.data.invoice.length; i++) {
            await printHandler(response?.data?.invoice[i]);
          }
          showSuccessToast("Invoice Downloaded");

          setIsLoading(false);
        }
        if (response.status == 500) {
          setIsLoading(false);
          showErrorToast("Server Error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showErrorToast(err);
      });
  };

  const printHandler = async (values) => {
    setData(values);
    const printElement = ReactDOMServer.renderToString(pdfJSX(values));
    html2pdf().from(printElement).save();
  };

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  const pdfJSX = (data) => {
    return (
      <>
        <div className="jumBotron invoice-container">
          <div className="header-invoice">
            <div>
              <div className="header-in d-flex align-items-center">
                <div style={{ width: "190px" }}>
                  <img src={data?.logo} alt="NM Logo" />
                </div>
                <div className="right-sec">
                  <h6 className="tax-heading">Tax Invoice</h6>
                  <h3 className="company_name">NM India Bio Private Limited</h3>
                  <div className="invoice-address">
                    Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur ,
                    Rajasthan - 313003, INDIA.
                  </div>
                  <div className="my-0 invoice-address ">
                    <span>9829355044</span>
                    <span className="mx-3">bhanuprakash@nmidiabio.com</span>
                  </div>
                  <div className="invoice-address">
                    <span>
                      GSTIN: <b>08AACCE7746A1ZT</b>
                    </span>
                    <span className="mx-3">
                      PAN: <b>AACCE7746A</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="invoice-bill mt-3">
                  <div className="invoice-bill-content">
                    <div className="invoice-date">
                      <span>INVOICE</span>
                      <br></br>
                      <span>{data?.vch_no}</span>
                      <br></br>
                      <span>{formatDate(data?.date)}</span>
                    </div>
                    <div className="invoice-person-detail">
                      <p style={{ marginBottom: "0" }}>Bill to</p>
                      <div className="tax-heading">{data?.tallyLedgerName}</div>
                      <span>Dealer Code : </span>
                      <br></br>
                      <span>
                        {data?.billing_address} , {data?.billing_city} , {data?.billing_state} , {data?.billing_zip}
                      </span>
                      <br></br>
                      <span>
                        GSTIN : {data?.gstin} (24) {data?.billing_state}
                      </span>
                    </div>
                  </div>
                  <div
                    className="invoice-person-detail"
                    style={{ borderLeft: "1px solid black" }}
                  >
                    <p style={{ marginBottom: "0" }}>Ship to</p>
                    <div className="tax-heading">{data?.tallyLedgerName}</div>
                    <span>Dealer Code : </span>
                    <br></br>
                    <span>
                      {data?.shipping_address} , {data?.shipping_city} , {data?.shipping_state} , {data?.shipping_zip}
                    </span>
                    <br></br>
                    <span>
                      GSTIN : {data?.gstin} (24) {data?.shipping_state}
                    </span>
                  </div>
                  <div className="invoice-bill-qrcode">
                    <img src="QR_code.webp" />
                  </div>
                </div>

                <table className="mid-line-table">
                  <tr>
                    <th className="first-th">IRN</th>
                    <th>eeeeeeer4i3eee5345i345345io345i3i45i354oi3</th>
                    <th>184838383838383</th>
                    <th>@ {formatDate(data?.date)}</th>
                  </tr>
                </table>

                <table>
                  <tr>
                    <th></th>
                    <th>Order</th>
                    <th>Dispatch Doc</th>
                    <th>Through</th>
                    <th>LR/RR/BL</th>
                    <th>Carrier</th>
                  </tr>

                  <tr>
                    <td>#</td>
                    <td>{data?.orderNo}</td>
                    <td>{data?.vch_no}</td>
                    <td>{data?.transporterName}</td>
                    <td>6522</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>@</td>
                    <td>{formatDate(data?.date)}</td>
                    <td></td>
                    <td></td>
                    <td className="date">{formatDate(data?.date)} </td>
                    <td>{data?.carrierNumber}</td>
                  </tr>
                </table>

                <table className="main-table">
                  <thead>
                    <tr>
                      <th className="s_no">S.No</th>
                      <th className="product_name_td">Description of Goods</th>
                      <th className="hsn_td1">HSN</th>
                      <th>Quantity</th>
                      <th>Weight</th>
                      <th>Carton</th>
                      <th>Rate</th>
                      <th>Per</th>
                      <th>Disc. %</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.products?.map((product, index) => (
                      <>
                        <tr className="border-0" key={index}>
                          <td>{index + 1}</td>
                          <td className="product_name_td">
                            {product.product_name}
                          </td>
                          <td>310100</td>
                          <td>{`${product.quantity} ${product.base_unit}`}</td>
                          <td>{`${product.weight} ${product.unit}`}</td>
                          <td>-</td>
                          <td>{`${product.rate}.00`}</td>
                          <td>{product.base_unit}</td>
                          <td>-</td>
                          <td>{`${product.item_amount}.00`}</td>
                        </tr>
                      </>
                    ))}
                    <tr className="border-0" style={{ height: "30px" }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>-</b>
                      </td>
                    </tr>

                    <tr className="border-0">
                      <td></td>
                      <td
                        className="d-flex justify-content-between"
                        style={{ paddingRight: "5px" }}
                      >
                        <b>
                          <i>Less</i>
                        </b>
                        <b>
                          <i>Discount</i>
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>(-)00.00</b>
                      </td>
                    </tr>

                    {data?.itemsTax?.cgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>CGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.itemsTax?.sgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>SGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.itemsTax?.igst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>IGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.itemsTax?.igst_amount}</b>
                        </td>
                      </tr>
                    )}

                    <tr style={{ height: "200px" }} className="border-0">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td></td>
                      <td>
                        <b>
                          {data?.quantity} {data?.baseUnit}
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>Rs. {data?.total}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="amount-chargeble">
                  <tbody>
                    <div
                      className="d-flex"
                      style={{
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid #000",
                          width: "80%",
                          padding: "0px 5px",
                        }}
                      >
                        <div className="d-flex justify-content-between amount-words">
                          <p className="m-0 amount">
                            <b>Amount Chargeable (In words)</b>
                          </p>
                          <p className="mx-1 mb-0 eof text-right">
                            <b>E. & O.E</b>
                          </p>
                        </div>
                        <div className="mb-0 amount-eng">
                          <b>INR {data?.totalInWords}</b>
                        </div>
                        <p></p>
                      </div>

                      <div style={{ padding: "0px 5px", margin: "auto" }}>
                        <div className="d-flex justify-content-between">
                          <b>Freight Payable :</b>
                          <b style={{ paddingLeft: "5px" }}> 00.00</b>
                        </div>
                        <div className="d-flex justify-content-between">
                          <b>Due Amount :</b>
                          <b style={{ paddingLeft: "5px" }}> 00.00</b>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>

                <table className="tax-amount">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>HSN/SAC</th>
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Taxable Value
                      </th>
                      {data?.itemsTax?.cgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Central Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.itemsTax?.sgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>State Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.itemsTax?.igst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Integrated Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Total Tax Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>310100</td>
                      <td style={{ width: "15%", textAlign: "center" }}>{data?.total -data?.total_tax}</td>

                      {data?.itemsTax?.cgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.itemsTax?.cgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                              {data?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.itemsTax?.sgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.itemsTax?.sgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                              {data?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.itemsTax?.igst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.itemsTax?.igst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.itemsTax?.igst_amount}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.total_tax}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pt-2 pl-1 bank_details d-flex justify-content-between border border-dark">
                  <div className="d-flex flex-column justify-content-between w-50 pr-4">
                    <span className="d-flex">
                      Tax Amount (in Words) :&nbsp;
                      <b>INR {data?.totalTaxInWords}</b>
                      {/* <!-- Enter amount in words  --> */}
                    </span>
                    <div>
                      <p className="mb-0 pt-2">
                        <p
                          className="mb-0"
                          style={{ textDecoration: "underline" }}
                        >
                          Declaration :
                        </p>
                        We Declare that this invoice shows the real price of the
                        goods described and that all particular true and
                        correct.
                      </p>
                    </div>
                  </div>
                  <div className="company_div w-50">
                    <div className="mt-0">
                      <p className="mb-0">Company's Bank Details</p>
                      <p className="mb-0">
                        Bank Name : <b>Bank of Baroda (India)</b>
                      </p>
                      <p className="mb-0">
                        A/c No. : <b>01370500000868</b>
                      </p>
                      <p className="mb-0">
                        Branch & IFSC Code :{" "}
                        <b>Udaipur Main Branch & BARB0UDAIPU</b>
                      </p>
                    </div>
                    <div
                      className="invoice-bill-qrcode qr-code border border-dark w-100 h-100 p-1"
                      style={{ borderBottom: "0px !important" }}
                    >
                      <div>
                        <div className="text-left">
                          <b>Scan to Pay</b>
                        </div>
                        <div className="mt-1">
                          <img
                            src={data?.qr}
                            style={{ width: "140px", height: "100px" }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between mt-3">
                        <p style={{ fontWeight: "600" }}>
                          For{" "}
                          <span style={{ fontWeight: "bold" }}>
                            NM India Bio Private Limited
                          </span>{" "}
                        </p>
                        <p className="mb-0">Authorized Signatory</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border border-dark text-center p-2">
                  This is a computer generated Invoice
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };



  return (
    <div>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Edit Order</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/order">Order</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">Edit Order</li>
            </ol>
          </div>

          <div className="tab-content ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  {/* order number */}
                  <div className="row mx-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>
                          Order Number :<span className="required">*</span>
                        </label>
                        <input
                          id="order_no"
                          type="number"
                          className="form-control"
                          name="order_no"
                          value={formik.values.order_no}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mx-3">
                    {dearlerDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Dealer :<span className="required">*</span>
                            </label>
                            <select
                              id="dealer_id"
                              className="form-control"
                              name="dealer_id"
                              disabled={freezed}
                              // onChange={formik.handleChange}
                              onChange={(event) =>
                                setDealerIdHandler(event.target.value)
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.dealer_id}
                            >
                              <option value="">Select Dealer</option>
                              {dealerRoles.map((role) => (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.dealer_id &&
                            formik.errors.dealer_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.dealer_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* dealer select */}

                    {areamanagerDropDown ? (
                      <>
                        {/* Area Manager Select */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Area Manager:<span className="required">*</span>
                            </label>
                            <select
                              id="areamanager_id"
                              className="form-control"
                              name="areamanager_id"
                              disabled={freezed}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.areamanager_id}
                            >
                              <option value="">Select Area Manager</option>
                              {areaManagerRoles.map((role) =>
                                role.id != null ? (
                                  <option key={role.name} value={role.id}>
                                    {role.name}
                                  </option>
                                ) : (
                                  ""
                                )
                              )}
                            </select>
                            {formik.touched.areamanager_id &&
                            formik.errors.areamanager_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.areamanager_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* sales person dropdown */}

                    {salesmanDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Sales Person:<span className="required">*</span>
                            </label>
                            <select
                              id="salesman_id"
                              disabled={freezed}
                              className="form-control"
                              name="salesman_id"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.salesman_id}
                            >
                              <option value="">Select Sales Person</option>
                              {salesRoles.map((role) => (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.salesman_id &&
                            formik.errors.salesman_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.salesman_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* table */}
                  <div className={`card-body ${classes.tableWrapper}`}>
                    <table
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        {/* table heading */}
                        <tr>
                          <th>
                            <label>
                              Product:<span className="required">*</span>
                            </label>
                          </th>
                          <th>
                            <label>
                              Quantity :<span className="required">*</span>
                            </label>
                          </th>
                          <th>
                            <label>
                              Type:<span className="required">*</span>
                            </label>
                          </th>

                          {/* {userData?.role !== 4 ? ( */}
                          <th>
                            <label>
                              Price:<span className="required"></span>
                            </label>
                          </th>
                          {/* ) : (
                            ""
                          )} */}
                          <th>
                            <label>
                              Shipping Address:
                              <span className="required"></span>
                            </label>
                          </th>
                          <th>
                            {userData?.role === 4 && (
                              <label>
                                Action:<span className="required">*</span>
                              </label>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <input
                              id="id"
                              type="hidden"
                              className="form-control"
                              name="id"
                              readOnly={freezed}
                              value={formik.values.id}
                            />

                            {/* product dropdown */}
                            <td style={{ width: "33%" }}>
                              <select
                                id="stock_id"
                                className="form-control"
                                name="stock_id"
                                disabled={freezed}
                                value={item.stock_id}
                                // value={formik.values.stock_id}
                                onChange={(event) => {
                                  const selectedRole = event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? { ...data, stock_id: selectedRole }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event);
                                }}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">Select Product</option>
                                {roles.map((stock_id) => (
                                  <option key={stock_id.id} value={stock_id.id}>
                                    {stock_id.tally_name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.stock_id &&
                              formik.errors.stock_id ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.stock_id}
                                </div>
                              ) : null}
                            </td>
                            {/* Quantity */}
                            <td style={{ width: "15%" }}>
                              <input
                                id="qty"
                                type="number"
                                className="form-control"
                                name="qty"
                                readOnly={freezed}
                                value={item.qty || item?.dispatch_quantity}
                                onChange={(event) => {
                                  const qtyValue = event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? { ...data, qty: qtyValue }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event); // Call handleChange with the event
                                }}
                              />
                              {formik.touched.qty && formik.errors.qty ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.qty}
                                </div>
                              ) : null}
                            </td>
                            <td style={{ width: "15%" }}>
                              {item.qty ? (
                                <span>Ordered</span>
                              ) : (
                                <span>Dispatched</span>
                              )}
                            </td>
                            {/* price */}
                            {/* {userData?.role != 4 ? ( */}
                            <td style={{ width: "15%" }}>
                              <input
                                id="price"
                                type="number"
                                className="form-control"
                                name="price"
                                readOnly={freezed}
                                value={item.price}
                                onChange={(event) => {
                                  const priceValue = event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? { ...data, price: priceValue }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event);
                                }}
                              />
                            </td>
                            {/* ) : (
                              ""
                            )} */}
                            {/* Shipping Address */}
                            <td style={{ width: "100%" }}>
                              <select
                                id="shipping_address_id"
                                className="form-control"
                                name="shipping_address_id"
                                disabled={freezed}
                                value={item.shipping_address_id}
                                onChange={(event) => {
                                  const selectedSippingAddress =
                                    event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? {
                                            ...data,
                                            shipping_address_id:
                                              selectedSippingAddress,
                                          }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event);
                                }}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">
                                  Select Shipping Address
                                </option>
                                {shippingAddress.map((shipping) => (
                                  <option key={shipping.id} value={shipping.id}>
                                    {shipping?.shipping_address}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.shipping_address_id &&
                              formik.errors.shipping_address_id ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.shipping_address_id}
                                </div>
                              ) : null}
                            </td>
                            {userData?.role === 4 && (
                              <td className="text-center">
                                {item?.dispatch_status === 0 ? (
                                  <span>-</span>
                                ) : (
                                  <button
                                    onClick={(event) => {
                                      generateInvoice(
                                        event,
                                        item?.split_id,
                                        item?.merge_id,
                                        item?.order_id,
                                        item?.order_no
                                      );
                                    }}
                                    className="btn btn-default btn-sm ml-1 download-invoice"
                                  >
                                    Download Invoice
                                  </button>
                                )}
                              </td>
                            )}

                            {!freezed ? (
                              <td style={{ width: "33%" }}>
                                <div className={classes.actionBtns}>
                                  <button
                                    className={`btn ${classes.addBtn}`}
                                    onClick={addNewRow}
                                  >
                                    <GrAddCircle />
                                  </button>
                                  {tableData.length > 1 && (
                                    <button
                                      className={`btn ${classes.removeBtn}`}
                                      onClick={(event) =>
                                        removeExpensesTask(index, event)
                                      }
                                    >
                                      <GrSubtractCircle />
                                    </button>
                                  )}
                                </div>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* discount field */}
                  {/* <div className="col-lg-9 col-md-9 col-sm-12 offset-lg-3 offset-md-3"> */}
                  <div
                    className="row"
                    style={{ width: " 97%", margin: "auto" }}
                  >
                    {/* total Amount */}
                    <div className="col-lg-6 col-md-4 col-sm-12">
                      <label>
                        Total Order Amount:<span className="required"></span>
                      </label>

                      <div className="form-group">
                        <input
                          id="total_amount"
                          type="number"
                          className="form-control"
                          name="total_amount"
                          // value={calculateTotalAmount()}
                          value={formik.values.order_amount}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12">
                      <label>
                        Total Dispatch Amount:<span className="required"></span>
                      </label>

                      <div className="form-group">
                        {}
                        <input
                          id="dispatchAmount"
                          type="number"
                          className="form-control"
                          name="dispatchAmount"
                          // value={calculateTotalAmount()}
                          value={amount}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* SeasonDrowpDown */}
                  {seasonDropDown ? (
                    <div className="col-lg-12 col-md-12 col-sm-12 px-4 ">
                      <div className="form-group">
                        <label>Season</label>
                        <select
                          id="season_id"
                          className="form-control "
                          name="season_id"
                          onChange={(event) => {
                            let discountPercentage = 0;
                            seasonRoles.map((seasons) => {
                              if (
                                seasons.id === event.target.value &&
                                seasons.discount_percentage != ""
                              ) {
                                return (discountPercentage =
                                  seasons.discount_percentage);
                              }
                            });
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "discount",
                              discountPercentage
                            );
                            setDiscount(discountPercentage);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.season_id}
                        >
                          <option value="">Select Area Manager</option>
                          {seasonRoles.map((role) => (
                            <option key={role.name} value={role.id}>
                              {role.season_title}
                            </option>
                          ))}
                        </select>
                        {formik.touched.season_id && formik.errors.season_id ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.season_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="row"
                    style={{ width: " 97%", margin: "auto" }}
                  >
                    {logicticDropDown ? (
                      <div className="col-lg-6 col-md-6 col-sm-6 ">
                        <div className="form-group">
                          <label>Order Assign To :</label>
                          <select
                            id="logistic_user_id"
                            className="form-control "
                            name="logistic_user_id"
                            disabled={freezed}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.logistic_user_id}
                          >
                            <option value="">Select Logistic Manager</option>
                            {logicticRoles.map((role) =>
                              role.id != null ? (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ) : (
                                ""
                              )
                            )}
                          </select>
                          {formik.touched.logistic_user_id &&
                          formik.errors.logistic_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.logistic_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {getData.order_status == "pending" ? (
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label>Order Status :</label>
                          <select
                            id="order_status"
                            className="form-control"
                            name="order_status"
                            disabled={freezed}
                            value={formik.order_status}
                            onChange={(event) => {
                              formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Status</option>
                            <option key="pendingStatus##1" value="pending">
                              Draft
                            </option>
                            <option key="confirmedStatus##1" value="confirmed">
                              Send to logistic
                            </option>
                          </select>
                          {formik.touched.order_status &&
                          formik.errors.order_status ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.order_status}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                        Image:<span className="required">*</span>
                      </label>

                      <div className="form-group align-items-center">
                        <input
                          id="image"
                          name="image"
                          type="file"
                          className="form-control"
                          placeholder="Images"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "image",
                              e.currentTarget.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                          disabled={freezed}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.image}
                          </div>
                        ) : null}
                        {typeof formik.values.image == "string" ? (
                          <a
                            href={`${process.env.REACT_APP_COMMON_FILE_URL}orders/${formik.values.image}`}
                            target="_blank"
                          >
                            <img
                              className="mt-2"
                              src={
                                process.env.REACT_APP_COMMON_FILE_URL +
                                "orders/" +
                                formik.values.image
                              }
                              style={{ width: "80px" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 ">
                      <div className="form-group">
                        <label>Remarks :</label>
                        <textarea
                          id="user_note"
                          className="form-control"
                          rows="4"
                          disabled={freezed}
                          name="user_note"
                          placeholder="Please Enter Remarks"
                          value={formik.values.remark}
                          onChange={(event) => {
                            formik.handleChange(event);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.user_note && formik.errors.user_note ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.user_note}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {userData?.role == "3" && (
                      <div className="col-lg-6 col-md-6 col-sm-6 ">
                        <div className="form-group">
                          <label>Area Manger Remark :</label>
                          <textarea
                            id="area_manager_remark"
                            className="form-control"
                            rows="4"
                            disabled={freezed}
                            name="area_manager_remark"
                            placeholder="Please Enter Remark"
                            value={formik.values.area_manager_remark}
                            onChange={(event) => {
                              formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-12 submit-btn my-2">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/order")}
                      >
                        Close
                      </button>
                      {!freezed ? (
                        <button type="submit" className="btn btn-primary ml-2">
                          Submit
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
